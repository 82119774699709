import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import ContactContent from '../components/Contact/ContactContent'
import { getContact } from '../api/contact'
import { navigate } from 'gatsby'

const Contact = ({ location }) => {
    const [contact, setContact] = useState(null)
    const [contacts, setContacts] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                setContact(null)
                setContacts(null)
                const data = await getContact(slug)
                setContact(data.contact)
                setContacts(data.contacts)
            } catch (error) {
                setContact(null)
                setContacts(null)
                navigate('/404')
            }
        })()
    }, [slug])

    if (!contact) return null

    return (
        <Layout title={contact.name}>
            <PageBanner />
            <ContactContent
                contact={contact}
                contacts={contacts}
            />
        </Layout>
    )
}

export default Contact