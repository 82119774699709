import React from 'react'
import { Link } from 'gatsby'

const FacilityCard = ({ facility }) => {
    const { media, name, slug } = facility

    var images = media.filter(media => media.collection_name === 'facilities');

    return (
        <div className='col-lg-4 col-md-6'>
            <Link to={`/facility-details?slug=${slug}`}>
                <div className='single-areas-box'>
                    <div className='image'>
                        {images.length > 0 ?
                            <img src={images[0].original_url}  />
                            : null
                        }
                        <div className='link-btn'>
                            <i className='bx bx-plus'></i>
                        </div>
                    </div>
                    <div className='content'>
                        <h3>{name}</h3>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default FacilityCard