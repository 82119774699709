import React from 'react'
import DocumentCard from './DocumentCard'
import RelatedContact from './RelatedContact'

const ContactSidebar = ({ contact, contacts }) => {
    const { address, description, documents, email, phone, short_description } = contact

    const city = JSON.parse(localStorage.getItem('settings')).filter(item => item.key === 'contact.address.line_2')[0].value

    return (
        <div className={description || short_description ? 'contact-sidebar' : 'contact-sidebar p-0'}>
            <div className={description || short_description ? 'contact-sidebar-info' : 'contact-sidebar-info m-0'}>
                <ul>
                    {phone ?
                        <li>
                            <div className='icon'>
                                <i className='bx bx-user-pin'></i>
                            </div>
                            <span>Teléfono:</span>
                            <a href={`tel:${phone}`} className='text-break'>{phone}</a>
                        </li>
                        : null
                    }
                    {address ?
                        <li>
                            <div className='icon'>
                                <i className='bx bx-map'></i>
                            </div>
                            <span>Dirección:</span>
                            {address}
                            <a href={`https://www.google.es/maps/place/${address},${city}/`} target='_blank' rel='noreferrer'>
                                Como llegar
                            </a>
                        </li>
                        : null
                    }
                    {email ?
                        <li>
                            <div className='icon'>
                                <i className='bx bx-envelope'></i>
                            </div>
                            <span>Correo Electrónico:</span>
                            <a href={`mailto:${email}`} className='text-break'>{email}</a>
                        </li>
                        : null
                    }
                    {documents.length > 0 ?
                        <li>
                            <div className='icon'>
                                <i className='bx bx-file'></i>
                            </div>
                            <span>Adjuntos:</span>
                            {documents.map((document) => {
                                return document.media.map((media) => {
                                    return (
                                        <DocumentCard
                                            key={`media-${media.id}`}
                                            media={media}
                                        />
                                    )
                                })
                            })}
                        </li>
                        : null
                    }
                    {contacts ?
                        <li>
                            <div className="icon">
                                <i className='bx bx-user-pin'></i>
                            </div>
                            <span>Relacionados:</span>
                            {contacts.map((contact) => (
                                <RelatedContact
                                    key={contact.id}
                                    contact={contact}
                                />
                            ))}
                        </li>
                        : null
                    }
                </ul>
            </div>
        </div>
    )
}

export default ContactSidebar