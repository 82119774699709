import React from 'react'
import ContactInfo from './ContactInfo'
import ContactFooter from './ContactFooter'
import ContactSidebar from './ContactSidebar'

const ContactContent = ({ contact, contacts }) => {
    const { description, short_description } = contact

    return (
        <section className='page-area ptb-50'>
            <div className='container'>
                <div className='row'>
                    <div className={description || short_description ? 'col-lg-8 col-md-12 mb-4' : 'col-12 mb-4'}>
                        <ContactInfo
                            contact={contact}
                            key={contact.id}
                        />
                    </div>
                    <div className={description || short_description ? 'col-lg-4 col-md-12 col-12' : 'col-12'}>
                        {contacts ?
                            <ContactSidebar
                                contact={contact}
                                contacts={contacts}
                            />
                            :
                            null
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 mb-4'>
                        <ContactFooter
                            contact={contact}
                            key={contact.id}
                        />
                    </div>
                </div>

            </div>
        </section>
    )
}

export default ContactContent