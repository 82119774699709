import React from 'react'
import FacilityCard  from '../Facilities/FacilityCard'
const ContactFooter = ({ contact }) => {



    return (
        <>

            <div className='row'>
                {contact.facilities.length > 0 && contact.facilities.map((item) => {
                    return (
                        <FacilityCard
                            key={item.id}
                            facility={item}
                        />
                    )
                })}
            </div>

        </>
    )
}

export default ContactFooter